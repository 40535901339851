import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Наші послуги | Дослідіть нашу колекцію на Solarixy Vinyls
			</title>
			<meta name={"description"} content={"Кожен запис - нове відкриття"} />
			<meta property={"og:title"} content={"Наші послуги | Дослідіть нашу колекцію на Solarixy Vinyls"} />
			<meta property={"og:description"} content={"Кожен запис - нове відкриття"} />
			<meta property={"og:image"} content={"https://solarixy.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://solarixy.com/img/3751553.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://solarixy.com/img/3751553.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://solarixy.com/img/3751553.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://solarixy.com/img/3751553.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://solarixy.com/img/3751553.png"} />
			<meta name={"msapplication-TileImage"} content={"https://solarixy.com/img/3751553.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Наша колекція чекає на вас
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
				У Solarixy Vinyls ми віримо у здатність музики розповідати історії, викликати емоції та об'єднувати людей. Наша колекція - це ретельно відібрані вінілові платівки, кожна з яких має власну історію та душу. Ми запрошуємо вас дослідити різноманітні звуки та жанри, що наповнюють наші полиці, від класики, яка сформувала покоління, до прихованих перлин, що чекають на своє відкриття.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://solarixy.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Легенди класичного року
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						The Beatles: Пориньте в дискографію "Fab Four", від їхніх новаторських ранніх робіт до інноваційних останніх альбомів.
							<br />
							<br />
							
Led Zeppelin: Відчуйте силу і містику Led Zeppelin з альбомами, які змінили рок-музику.

						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://solarixy.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="20px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Jazz Essentials
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Майлз Девіс: Дослідіть холодні, модальні та ф'южн-звуки Майлза Девіса, справжнього піонера джазу.
							<br />
							<br />
							
Джон Колтрейн: Відкрийте для себе революційні звукові ландшафти Колтрейна, від бібопа до спіричуелс джазу.

						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://solarixy.com/img/7.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="20px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Соул та фанк
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Джеймс Браун: Get offa that thing with the Godfather of Soul's electrifying funk hits.
							<br />
							<br />
							Арета Франклін: Поважайте королеву соулу та її потужний, хвилюючий душу вокал.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Дізнатися більше
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Наша колекція постійно розвивається, регулярно поповнюючись новими надходженнями та рідкісними знахідками, які дивують навіть найдосвідченіших колекціонерів. Щоб відкрити для себе найновіші скарби та дізнатися більше про нашу поточну колекцію, ми запрошуємо вас зв'язатися з нами або, ще краще, відвідати нас особисто. У Solarixy завжди можна знайти щось нове, а наш привітний персонал завжди готовий провести вас через світ вінілу. Дозвольте нам допомогти вам знайти ідеальну платівку для поповнення вашої колекції або створення нової. Історії в наших стінах нескінченні, і кожен візит обіцяє нові відкриття.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://solarixy.com/img/7.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});